import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CoManageDataAccessObject } from '@safarilaw-webapp/feature/co-manage/data-access';
import { HeaderComponent } from '@safarilaw-webapp/shared/app-bootstrap';
import { SafariObject } from '@safarilaw-webapp/shared/common-objects-models';
import { SharedObservablesDirective } from '@safarilaw-webapp/shared/ui-kit/shared-observables-directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector -- ignore NG
  selector: 'sl-bootstrap-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [RouterLink, AsyncPipe, SharedObservablesDirective, BsDropdownModule]
})
export class LpmsHeaderComponent extends HeaderComponent implements OnInit {
  /**
   *
   * @param CoManageObject WATCH IT here ! This will import the whole module
   * and LpmsHeaderComponent is used during app bootstrapping. So this has to be lean.
   *
   * For now it's fine for CoManageDataAccess as it is a fairly small module.
   * But you wouldn't want to import something like LpmsDataAccessObject since that would
   * significantly boost boostrapping module size.
   *
   * Also - if CoManageDataAccess becomes too big in the future we'll probaly have to split out
   * this functionality into another DataAccessModule called CoManageMyDataAccess or something like that
   * and then this would refer to that instead (and CoManageDataAccess would roll that in behind the scenes)
   */
  public CoManageObject: CoManageDataAccessObject;

  constructor() {
    super();
    this.CoManageObject = this.inject(CoManageDataAccessObject);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.currentUserInfo$ = this.observeObject$(this.CoManageObject.MyUser, SafariObject.NOID);
  }
}
