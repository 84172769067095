import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd } from '@angular/router';

import {
  AppUpdateSplashService,
  NavbarService,
  SafariRootAppComponent,
  SwCheckForUpdateService,
  SwHandleUnrecoverableStateService,
  SwHandleUpdateService
} from '@safarilaw-webapp/shared/app-bootstrap';
import { FeatureFlagsService } from '@safarilaw-webapp/shared/environment';
import { ReduxWrapperService } from '@safarilaw-webapp/shared/redux';
import { Subscription } from 'rxjs';

import { NavbarItemCollection } from './navbar-item-collection';
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector -- ignoring naming convention for root component
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: false
})
export class AppComponent extends SafariRootAppComponent implements OnInit, OnDestroy {
  showSplash = false;
  private _appUpdateSplashServiceSubscription: Subscription = null;
  constructor(
    injector: Injector,

    navbarService: NavbarService,
    private _featureFlagService: FeatureFlagsService,

    private _reduxWrapperService: ReduxWrapperService,

    // NOTE: When using these four services make sure they are added in this order. Their constructors
    // will fire in order and we want the ones that subscribe to SW to construct before the one that
    // actually issues the update check. They look unused but they are not - leave them here
    private _handleRecovery: SwHandleUnrecoverableStateService,
    private _logUpdate: SwHandleUpdateService,
    private _checkUpdate: SwCheckForUpdateService,
    ///////////////////////////////////////////////////////////////////////////////////////////////////
    private _appUpdateSplashService: AppUpdateSplashService
  ) {
    super(injector, navbarService);
    this._navbarItems = [...NavbarItemCollection];
  }

  ngOnInit(): void {
    this._appUpdateSplashServiceSubscription = this._appUpdateSplashService.subscribe(() => {
      this.showNavbar = this.showHeader = false;
      this.showSplash = true;
    });

    super.ngOnInit();
  }
  ngOnDestroy(): void {
    if (this._appUpdateSplashServiceSubscription) {
      this._appUpdateSplashServiceSubscription.unsubscribe();
      this._appUpdateSplashServiceSubscription = null;
    }
    super.ngOnDestroy();
  }
  OnNavigationEnd(evt: NavigationEnd) {
    super.OnNavigationEnd(evt);
  }

  /** BVT Testing support */
  get shouldIncludeRouteNavigator(): boolean {
    return this._appConfig.featureFlags.devTools;
  }
  /** BVT Testing support */
  doNavigation($event) {
    this._router.navigate([$event.target.parentNode.childNodes[0].value]);
  }
}
