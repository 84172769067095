import { IdName } from '@safarilaw-webapp/shared/common-objects-models';

/**
 * Extracts numeric values and their corresponding string lookup values from an enum type
 *
 * @param e An enumeration type
 * @returns [number, string][]
 */
export const getEnumValues = (e: any): [number, string][] => {
  const enumeration = [];

  if (!e) {
    return enumeration;
  }

  const values = Object.values(e).filter(o => typeof o === 'number');
  for (const value of values) {
    enumeration.push([value, e[value]]);
  }
  return enumeration;
};

export const getEnumValuesAsIdName = (e: any): IdName[] => getEnumValues(e).map(([id, name]) => ({ id, name }));
